const apps = [
    'asm-services',
    'bill',
    'boxoffice',
    'core',
    'diagnostics',
    'engineer-tracking',
    'help-articles',
    'help-search',
    'help-new',
    'help-expert',
    'mobile',
    'mobile-service',
    'mobile-bill',
    'mobile-returns',
    'mysky',
    'myphoto',
    'search',
    'signin',
    'sky-yahoo-mail-manage-apps',
    'talkshield',
    'wifi-finder',
    'program-your-remote'
];

const supportDefaultEsmExports = m => m.default ? m.default : m;

const loadApplication = (appName) => {
    if (process.env.WEBPACK_BUILD) {
        // eslint-disable-next-line no-undef
        return import(/* webpackChunkName: "pages-app-[request]" */ `appContext/${appName}/index.js`)
            .then(supportDefaultEsmExports)
            .catch((error) => {
                throw new Error(`Failed to load application "${appName}": ${error}`);
            });
    } else {
        let app;

        try {
            app = supportDefaultEsmExports(require(`./${appName}`));
        } catch (error) {
            throw new Error(`Failed to load application "${appName}": ${error.stack}`);
        }

        try {
            app.server = require(`./${appName}/server`);
        } catch (error) {
            // TODO: Enforce server code exposure via server.js.
            // Do nothing, for now.
        }

        return app;
    }
};

export const loadApplications = (appManifest) => {
    return appManifest.reduce((loadingApps, appName) => {
        loadingApps[appName] = loadApplication(appName);

        return loadingApps;
    }, {});
};

export const getAppsToLoad = () => {
    if (process.env.PAGES_APPS) {
        return process.env.PAGES_APPS.split(',');
    }

    return apps;
};

export const getActiveApps = (appNames = getAppsToLoad()) => {
    return apps.filter((appName) => {
        return appNames.includes(appName);
    });
};

export default apps;
